<template>
  <el-form style="margin-bottom: 20px;" @submit.prevent="doFilter">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item>
          <el-date-picker
            v-model="state.filter.dateOrder"
            type="daterange"
            start-placeholder="Дата заказа С"
            end-placeholder="Дата заказа По"
            format="DD.MM.YYYY"
            :editable="false"
            :shortcuts="pickerShortcuts"
            unlink-panels
            clearable
            :disabled="disableFilter"
          />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item>
          <el-date-picker
            v-model="state.filter.dateExpected"
            type="daterange"
            start-placeholder="Дата ориент С"
            end-placeholder="Дата ориент По"
            format="DD.MM.YYYY"
            :editable="false"
            :shortcuts="pickerShortcuts"
            unlink-panels
            clearable
            :disabled="disableFilter"
          />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item>
          <el-date-picker
            v-model="state.filter.dateAssemble"
            type="daterange"
            start-placeholder="Дата сборки С"
            end-placeholder="Дата сборки По"
            format="DD.MM.YYYY"
            :editable="false"
            :shortcuts="pickerShortcuts"
            unlink-panels
            clearable
            :disabled="disableFilter"
          />
        </el-form-item>
      </el-col>
      <el-col :span="6" v-if="canViewAllFields">
        <el-form-item>
          <master-autocomplete v-model="state.filter.masterId" placeholder="Мастер" :disabled="disableFilter" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20" v-if="canViewAllFields">
      <el-col :span="6">
        <el-form-item>
          <el-date-picker
            v-model="state.filter.datePaymentShop"
            type="daterange"
            start-placeholder="Дата оплаты магазину С"
            end-placeholder="Дата оплаты магазину По"
            format="DD.MM.YYYY"
            :editable="false"
            :shortcuts="pickerShortcuts"
            unlink-panels
            clearable
            :disabled="disableFilter"
          />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item>
          <el-date-picker
            v-model="state.filter.datePaymentMaster"
            type="daterange"
            start-placeholder="Дата оплаты мастеру С"
            end-placeholder="Дата оплаты мастеру По"
            format="DD.MM.YYYY"
            :editable="false"
            :shortcuts="pickerShortcuts"
            unlink-panels
            clearable
            :disabled="disableFilter"
          />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item>
          <el-date-picker
            v-model="state.filter.dateCall"
            type="daterange"
            start-placeholder="Дата созвона С"
            end-placeholder="Дата созвона По"
            format="DD.MM.YYYY"
            :editable="false"
            :shortcuts="pickerShortcuts"
            unlink-panels
            clearable
            :disabled="disableFilter"
          />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item>
          <multiple-select :options="categories" v-model="state.filter.categories" placeholder="Категория" :disabled="disableFilter" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item>
          <multiple-select :options="shops" v-model="state.filter.shops" placeholder="Магазин" />
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item>
          <multiple-select :options="orderTypes" v-model="state.filter.types" placeholder="Тип работ" :disabled="disableFilter" />
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item>
          <multiple-select :options="statusMapItems" v-model="state.filter.statuses" placeholder="Статус" :disabled="disableFilter || mode === 'success'" />
        </el-form-item>
      </el-col>

      <el-col :span="6" v-if="canViewAllFields">
        <el-form-item>
          <multiple-select :options="payments" v-model="state.filter.payments" placeholder="Оплата" :disabled="disableFilter" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item>
          <el-input v-model="state.filter.number" placeholder="Номер" clearable :disabled="disableFilter" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item>
          <el-input v-model="state.filter.customer" placeholder="Клиент (ФИО)" clearable :disabled="disableFilter" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item>
          <el-input v-model="state.filter.address" placeholder="Адрес (Улица)" clearable :disabled="disableFilter" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item>
          <el-input v-model="state.filter.phone" placeholder="Телефон" clearable :disabled="disableFilter" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-button type="success" native-type="submit">Применить</el-button>
    <el-button v-if="isFiltered" type="warning" @click="doReset">Сбросить</el-button>
  </el-form>
</template>

<script lang="ts" setup>
import pickerShortcuts from '@/utils/pickerShortcuts'
import statusMap from '@/data/statusMap'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { iOrderPayment, iOrderType, iShopItem } from '@/types/models'
import { useStore } from '@/store'
import restOrderPayments from '@/api/rest/restOrderPayments'
import { ElMessage } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import MultipleSelect from '@/components/MultipleSelect.vue'
import { extractDateValue, extractIds, makeDateFilterValue } from '@/utils/filter'
import MasterAutocomplete from '@/components/masters/MasterAutocomplete.vue'

export interface iFilter {
  dateOrder: Array<Date|string> | undefined
  dateExpected: Array<Date|string> | undefined
  dateAssemble: Array<Date|string> | undefined
  masterId: number|undefined
  datePaymentShop: Array<Date|string>|undefined
  datePaymentMaster: Array<Date|string>|undefined
  dateCall: Array<Date|string>|undefined
  categories: Array<number>
  shops: Array<number>
  types: Array<number>
  statuses: Array<number>
  customer: string
  number: string
  address: string
  phone: string
  payments: Array<number>
}

export type iFilterValues = {
  // eslint-disable-next-line no-unused-vars
  [index in keyof iFilter]: string;
};

export type iQueryParams = iFilterValues & {
  sort?: string
  page?: number
  mode?: string
}

const router = useRouter()
const route = useRoute()
const store = useStore()

const canViewAllFields = computed(() => store.getters.can('order_all_fields'))
const canViewCompleted = computed(() => store.getters.can('view_completed'))

const state = reactive<{
  filter: iFilter
}>({
  filter: {
    dateOrder: extractDateValue(route.query.dateOrder),
    dateExpected: extractDateValue(route.query.dateExpected),
    dateAssemble: extractDateValue(route.query.dateAssemble),
    masterId: route.query.masterId ? parseInt(route.query.masterId as string) : undefined,
    datePaymentShop: extractDateValue(route.query.datePaymentShop),
    datePaymentMaster: extractDateValue(route.query.datePaymentMaster),
    dateCall: extractDateValue(route.query.dateCall),
    categories: extractIds(route.query.categories),
    shops: extractIds(route.query.shops),
    types: extractIds(route.query.types),
    statuses: extractIds(route.query.statuses),
    customer: route.query.customer as string || '',
    number: route.query.number?.toString() || '',
    address: route.query.address as string || '',
    phone: route.query.phone as string || '',
    payments: extractIds(route.query.payments)
  }
})

const emit = defineEmits(['loading'])

const mode = computed(() => (route.query.mode || 'normal') as string)
const disableFilter = computed(() => ['danger', 'warning'].includes(mode.value))

const orderTypes = computed<Array<iOrderType>>(() => store.state.orderTypes)
const categories = computed(() => store.getters.categories)

const getFilterQuery = (): iFilterValues => ({
  address: state.filter.address,
  categories: state.filter.categories.join(','),
  customer: state.filter.customer,
  dateAssemble: makeDateFilterValue(state.filter.dateAssemble),
  dateCall: makeDateFilterValue(state.filter.dateCall),
  dateExpected: makeDateFilterValue(state.filter.dateExpected),
  dateOrder: makeDateFilterValue(state.filter.dateOrder),
  datePaymentMaster: makeDateFilterValue(state.filter.datePaymentMaster),
  datePaymentShop: makeDateFilterValue(state.filter.datePaymentShop),
  masterId: state.filter.masterId?.toString() || '',
  number: state.filter.number,
  payments: state.filter.payments.join(','),
  phone: state.filter.phone,
  shops: state.filter.shops.join(','),
  statuses: state.filter.statuses.join(','),
  types: state.filter.types.join(',')
})

const doFilter = () => {
  const filterQuery: iFilterValues = getFilterQuery()
  const query: iQueryParams = { ...filterQuery }
  if (route.query.sort) {
    query.sort = route.query.sort as string || ''
  }
  if (mode.value) {
    query.mode = mode.value
  }
  router.push({ query })
}

const statusMapItems = Object.keys(statusMap).filter(item => canViewCompleted.value || item !== '3').map((key: string) => ({
  id: parseInt(key),
  title: statusMap[key as unknown as keyof typeof statusMap] as string
}))

const shops = computed<Array<iShopItem>>(() => store.state.shops)
const payments = ref<iOrderPayment[]>([])

const loadPayments = () => restOrderPayments.list({ params: { fields: ['id', 'title'].join(',') } })
  .then(response => {
    payments.value = response.data
  })
  .catch(error => {
    ElMessage.error('Ошибка загрузки оплат')
    console.error(error)
  })

const isFiltered = computed(() => route.query.shops !== undefined)

const doReset = () => {
  switch (mode.value) {
    case 'success':
      router.push({
        query: {
          mode: 'success',
          statuses: '1,3',
          dateAssemble: route.query.dateAssemble
        }
      })
      break
    case 'normal':
      router.push({ query: {} })
      break
    default:
      router.push({ query: { mode: mode.value } })
  }
}

watch(() => route.query, () => {
  const filter = state.filter
  const query = route.query
  filter.address = query.address as string || ''
  filter.categories = extractIds(query.categories)
  filter.customer = query.customer as string || ''
  filter.dateAssemble = extractDateValue(query.dateAssemble)
  filter.dateCall = extractDateValue(query.dateCall)
  filter.dateExpected = extractDateValue(query.dateExpected)
  filter.dateOrder = extractDateValue(query.dateOrder)
  filter.datePaymentMaster = extractDateValue(query.datePaymentMaster)
  filter.datePaymentShop = extractDateValue(query.datePaymentShop)
  filter.masterId = query.masterId ? parseInt(query.masterId as string) : undefined
  filter.number = query.number?.toString() || ''
  filter.payments = extractIds(query.payments)
  filter.phone = query.phone as string || ''
  filter.shops = extractIds(query.shops)
  filter.statuses = extractIds(query.statuses)
  filter.types = extractIds(query.types)
})

onMounted(async () => {
  emit('loading', true)

  await loadPayments()

  emit('loading', false)
})

defineExpose({ getFilterQuery })

</script>
