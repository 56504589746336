<template>
  <div v-if="!props.shopId">Выберите магазин</div>
  <template v-else>
    <el-form>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item>
            <el-input placeholder="Поиск" v-model="state.filter.title" clearable />
          </el-form-item>
        </el-col>
        <el-col v-if="!simpleFilters" :span="6">
          <el-form-item>
            <el-select placeholder="Тип" v-model="typeId" :disabled="types.length === 1 || disableType">
              <el-option v-for="type in types" :key="type.id" :value="type.id" :label="type.title" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="!simpleFilters" :span="6">
          <el-form-item>
            <el-select placeholder="Категория" v-model="categoryId" clearable :disabled="disableCategory">
              <el-option v-for="category in categories" :key="category.value" :value="category.value" :label="category.label" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-table
      :data="tableData"
      :show-header="false"
      :row-class-name="tableRowClassName"
      empty-text="Выберите магазин"
    >
      <el-table-column prop="title" />
      <el-table-column prop="unit" width="100" />
      <el-table-column prop="price" width="100" />
      <el-table-column width="180">
        <template #default="scope">
          <el-input-number
            v-if="scope.row.id !== undefined"
            :model-value="props.modelValue[props.modelValue.findIndex(v => v.service_id === scope.row.id)]?.qty || 0"
            @update:modelValue="v => updateValue(scope.row.id, v, scope.row.price)"
            :min="0"
          />
          <template v-else>
            {{ scope.row.qty }}
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="total" width="100" />
    </el-table>
  </template>
</template>

<script setup lang="ts">

import { computed, reactive, watch } from 'vue'
import { iListItem, iService, iOrderService } from '@/types/models'
import { useStore } from '@/store'
import { tableRowClassName, makeServiceTableData } from '@/composables/useServicesTable'

const props = withDefaults(
  defineProps<{
    shopId: number | undefined,
    orderTypeId?: number
    categoryId?: number
    services: Array<iService>
    modelValue: Array<iOrderService>
    disableType: boolean,
    disableCategory: boolean
    simpleFilters?: boolean
  }>(),
  {
    disableType: false,
    disableCategory: false
  }
)

const emit = defineEmits(['update:modelValue'])
const store = useStore()

interface StateType {
  filter: {
    title: string
    typeId: number|undefined
    categoryId: number|undefined
  }
}
interface iSelectFilterItems {
  value: number
  label: string
}

const state = reactive<StateType>({
  filter: {
    title: '',
    typeId: undefined,
    categoryId: undefined
  }
})

const typeId = computed({
  get: () => props.orderTypeId || state.filter.typeId || types.value[0]?.id,
  set: (value: number | undefined) => {
    state.filter.typeId = value
    state.filter.categoryId = undefined
  }
})

const categoryId = computed({
  get: () => props.categoryId || state.filter.categoryId || (categories.value.length === 1 ? categories.value[0].value : undefined),
  set: (value: number | undefined) => { state.filter.categoryId = value }
})

const types = computed(() => {
  if (props.orderTypeId) {
    return store.state.orderTypes.filter((type: iListItem) => type.id === props.orderTypeId)
  }
  const services = new Set(props.services.map(i => i.order_type_id))
  return store.state.orderTypes.filter((type: iListItem) => services.has(type.id))
})

const categories = computed(() => {
  let result: iSelectFilterItems[] = []
  if (types.value.length) {
    const typeObj = types.value.find(i => i.id === typeId.value)

    if (props.categoryId) {
      if (props.services.length) {
        result.push({
          value: props.categoryId,
          label: typeObj?.categories.find(i => i.id === props.categoryId)?.title || 'Без категории'
        })
      }
      return result
    }

    const filteredServices = typeId.value === undefined
      ? props.services
      : props.services.filter(service => service.order_type_id === typeId.value)

    if (filteredServices.find(service => service.category_id === null)) {
      result.push({ value: -1, label: 'Без категории' })
    }

    const serviceCategories = filteredServices.map(i => i.category_id)

    result = result.concat(typeObj?.categories.filter(i => serviceCategories.includes(i.id)).map(i => ({
      value: i.id,
      label: i.title
    })) || [])
  }

  return result
})

const tableData = computed(() => {
  if (!categories.value.length) return []

  const services = props.services
    .filter(service => {
      if (service.is_archive) return false
      if (!service.title.toLowerCase().includes(state.filter.title.toLowerCase())) return false
      if (
        !(
          categoryId.value === undefined ||
          (categoryId.value === -1 && service.category_id === null) ||
          categoryId.value === service.category_id
        )
      ) return false
      if (!(typeId.value === undefined || service.order_type_id === typeId.value)) return false

      const categoryIds = categories.value.map(category => category.value)

      if (service.category_id !== null && !categoryIds.includes(service.category_id)) return false

      return true
    })
    .map(service => ({
      ...service,
      qty: props.modelValue.find(i => i.service_id === service.id)?.qty || 0
    }))

  return makeServiceTableData(services)
})

const updateValue = (id: number, qty: number, price: number) => {
  const model = [...props.modelValue]
  const index = model.findIndex(v => v.service_id === id)
  if (index < 0) {
    model.push({
      service_id: id,
      price,
      qty
    })
  } else {
    if (qty) {
      model[index].qty = qty
    } else {
      model.splice(index, 1)
    }
  }
  emit('update:modelValue', model)
}

watch(() => props.services, async (newServices, oldServices) => {
  const result: iOrderService[] = []

  if (!oldServices.length) {
    props.modelValue.forEach(item => {
      if (newServices.find(newService => newService.id === item.service_id)) {
        result.push(item)
      }
    })
  }

  emit('update:modelValue', result)
})

watch(() => props.shopId, () => {
  state.filter.typeId = undefined
  state.filter.categoryId = undefined
  state.filter.title = ''
})

</script>
