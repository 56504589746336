<template>
  <el-button type="primary" @click="handleAdd">Добавить</el-button>
  <tasks-table ref="tableRef" @edit="handleEdit" />
  <el-dialog v-model="dialogVisible">
    <tasks-form ref="formRef" @save="handleSave" />
  </el-dialog>
</template>

<script lang="ts" setup>
import TasksTable from '@/components/tasks/TasksTable.vue'
import { nextTick, ref } from 'vue'
import TasksForm from '@/components/tasks/TasksForm.vue'
import { iTask } from '@/types/models'

const dialogVisible = ref(false)

const formRef = ref()
const tableRef = ref()

const handleAdd = () => {
  dialogVisible.value = true
  nextTick(() => {
    formRef.value.resetForm()
  })
}

const handleEdit = (data: iTask) => {
  dialogVisible.value = true
  nextTick(() => {
    formRef.value.setData(data)
  })
}

const handleSave = () => {
  dialogVisible.value = false
  tableRef.value.load()
}
</script>
