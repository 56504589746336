<template>
  <div class="table-container">
    <el-table
      :data="tableData"
      ref="tableRef"
      empty-text="Ничего не нашлось"
      table-layout="fixed"
      :summary-method="getSummaries"
      show-summary
      :default-sort="getDefaultSort()"
      @sort-change="sortChange"
    >
      <el-table-column label="Действия" header-align="center" align="center" width="200">
        <template #default="scope">
          <el-button v-if="canUpdate" type="success" :icon="EditPen" circle @click="$emit('editRecord', scope.row.id)" />
          <el-button v-if="canCall" @click="toggleExpand(scope.row)" :icon="Phone" circle />
          <el-button @click="showHistory(scope.row.id)" :icon="Refresh" circle />
          <el-button @click="viewOrder(scope.row.order)" :icon="View" circle />
        </template>
      </el-table-column>

      <template v-for="column in columns" :key="column.key">
        <el-table-column v-if="column.key === 'customer'" :label="column.label" :width="tableWidth(column.key)">
          <template #default="scope">
            {{ scope.row.customer }}<br>
            {{ scope.row.phone }}
          </template>
        </el-table-column>
        <el-table-column v-else-if="column.key === 'services'" :label="column.label" :width="tableWidth(column.key)">
          <template #default="scope">
            <el-popover width="300">
              <template #reference>
                {{ scope.row.servicesTeaser }}
              </template>
              <span class="order-items-service" v-for="service in scope.row.services" :key="service.id">{{ service.title }}</span>
            </el-popover>

          </template>
        </el-table-column>
        <el-table-column v-else-if="column.key === 'files'" :label="column.label" :width="tableWidth(column.key)">
          <template #default="scope">
          <span class="order-items-service" v-for="file in scope.row.files" :key="file.id">
            <download-link :file="file" />
          </span>
          </template>
        </el-table-column>
        <el-table-column v-else-if="column.key === 'payment_info'" :label="column.label" :width="tableWidth(column.key)">
          <template #default="scope">
            <span class="payment-warning" v-if="scope.row.order.is_refund">Возврат</span>
            <template v-if="scope.row.order.is_payment_immediately">
              Оплата на месте
            </template>
            <template v-else-if="scope.row.order.is_paid">
              Оплата в магазине,<br>оплачено
            </template>
            <span v-else style="color: red;">Оплата в магазине,<br>не оплачено</span>
          </template>
        </el-table-column>
        <el-table-column v-else :prop="column.key" :label="column.label" :sortable="sortableFields.includes(column.key) ? 'custom' : false" :width="tableWidth(column.key)" />
      </template>

      <el-table-column v-if="canCall" class-name="col-hidden" width="0" type="expand">
        <template #default="scope">
          <call-table
            :calls="scope.row.calls"
            @update="e => emit('updateCall', e)"
            @delete="e => emit('deleteCall', e)"
          />
          <el-button @click="emit('createCall', scope.row.id)">Добавить</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts" setup>
import { EditPen, Phone, Refresh, View } from '@element-plus/icons-vue'
import { computed, ref } from 'vue'
import { iFieldSet, iListItem, iOrder, iOrderType, iOrderView, iService, iShopItem } from '@/types/models'
import { dateDecode, dateTimeDecode, formatCurrency, formatBool } from '@/utils/format'
import { sortableFields, orderFields, iOrderField, sellerFields } from '@/data/orderFields'
import { useStore } from '@/store'
import { useRoute, useRouter } from 'vue-router'
import { Sort, TableColumnCtx } from 'element-plus'
import DownloadLink from '@/components/orders/DownloadLink.vue'
import CallTable from '@/components/orders/call/CallTable.vue'

const store = useStore()
const router = useRouter()
const route = useRoute()
const emit = defineEmits(['editRecord', 'createCall', 'updateCall', 'deleteCall', 'showHistory', 'viewOrder'])

const props = defineProps<{
  summaries: Record<string, number>
  items: iOrderView[]
}>()

const template = computed<iFieldSet|undefined>(() => store.state.orderFieldSets.find(set => set.active === 1))

const orderTypes = computed<Array<iOrderType>>(() => store.state.orderTypes)
const shops = computed<Array<iShopItem>>(() => store.state.shops)
const partners = computed<Array<iListItem>>(() => store.state.partners)
const statuses = computed<Record<number, string>>(() => store.state.statuses)

const tableRef = ref()

const canUpdate = computed(() => store.getters.can('order_update'))
const canCall = computed(() => store.getters.can('calls'))

const columns = computed(() => {
  const columns = template.value
    ? template.value.fields.map((key: iOrderField) => ({
      key,
      label: orderFields[key as iOrderField]
    }))
    : Object.keys(orderFields).map((key) => ({
      key: key as iOrderField,
      label: orderFields[key as iOrderField]
    }))

  return store.getters.can('order_all_fields') ? columns : columns.filter(column => sellerFields.includes(column.key))
})

const tableData = computed(() => props.items.map<object>((order: iOrderView) => {
  const orderType = orderTypes.value.find(i => i.id === order.type_id)
  const calls = [...order.calls]
  calls.sort((a, b) => b.id - a.id)
  const lastCall = calls.shift()

  let servicesTeaser = (order.services as unknown as iService[]).map(service => service.title).join(', ')
  if (servicesTeaser.length > 40) {
    servicesTeaser = servicesTeaser.split(' ').reduce<string[]>((carry, item) => {
      const res = [
        ...carry,
        item
      ]
      return res.join(' ').length < 40 ? res : carry
    }, []).join(' ') + '...'
  }
  return {
    order,
    ...order,
    type_id: orderType?.title,
    address: order.address_street,
    date_order: dateDecode(order.date_order),
    category_id: orderType?.categories.find(i => i.id === order.category_id)?.title,
    date_assemble: dateDecode(order.date_assemble),
    date_expected: dateDecode(order.date_expected),
    date_call: dateDecode(order.date_call),
    cost: formatCurrency(order.cost),
    shop_id: shops.value.find(i => i.id === order.shop_id)?.title,
    payment_part_shop: formatCurrency(order.payment_part_shop),
    payment_part_master: formatCurrency(order.payment_part_master),
    payment_part_extra: formatCurrency(order.payment_part_extra),
    payment_part_master_extra: formatCurrency(order.payment_part_master_extra),
    payment_date_shop: dateDecode(order.payment_date_shop),
    payment_date_master: dateDecode(order.payment_date_master),
    is_urgent: formatBool(order.is_urgent),
    is_complaint: formatBool(order.is_complaint),
    is_refused: formatBool(order.is_refused),
    is_attention: formatBool(order.is_attention),
    status_id: statuses.value[order.status_id] || '',
    created_at: dateTimeDecode(order.created_at),
    updated_at: dateTimeDecode(order.updated_at),
    is_refund: formatBool(order.is_refund),
    is_payment_immediately: formatBool(order.is_payment_immediately),
    is_paid: formatBool(order.is_paid),
    has_debt: formatBool(order.has_debt),
    has_debt_extra: formatBool(order.has_debt_extra),
    is_doc_passed: formatBool(order.is_doc_passed),
    refund_date: dateDecode(order.refund_date),
    paid_date: dateTimeDecode(order.paid_date),
    doc_passed_date: dateDecode(order.doc_passed_date),
    partner_id: partners.value?.find(p => p.id === order.partner_id)?.title || '',
    master_id: order.master?.title,
    pre_master_id: order.pre_master?.title,
    company_debt: formatCurrency(order.company_debt),
    master_debt: formatCurrency(order.master_debt),
    is_lying: formatBool(order.is_lying),
    ignore_expected_date: formatBool(order.ignore_expected_date),
    call_is_time: lastCall ? formatBool(lastCall.is_time) : '',
    call_is_satisfy: lastCall?.rating || '',
    call_notes: lastCall?.note || '',
    payment_info: '',
    calls: order.calls,
    servicesTeaser
  }
}))

const sortChange = (data: { prop: string, order: 'descending' | 'ascending' | null }) => {
  const sort = data.order === null ? '' : (data.order === 'ascending' ? data.prop : '-' + data.prop)
  router.push({ query: { ...route.query, sort } })
}

const getDefaultSort = (): Sort|undefined => {
  if (route.query.sort) {
    const order = route.query.sort[0] === '-' ? 'descending' : 'ascending'
    const prop = order === 'descending' ? route.query.sort.toString().substring(1) : route.query.sort.toString()
    return { order, prop }
  }

  return undefined
}

const toggleExpand = (row: unknown) => {
  tableRef.value.toggleRowExpansion(row)
}

const tableWidth = (key: string) => {
  switch (key) {
    case 'id':
    case 'number':
      return 100
    case 'customer':
    case 'address':
      return 250
  }
  return 200
}

interface SummaryMethodProps<T = object> {
  columns: TableColumnCtx<T>[]
  data: T[]
}

const getSummaries = (param: SummaryMethodProps) => {
  const { columns } = param
  const sums: (string)[] = []

  columns.forEach((column, index) => {
    if (index === 0) {
      sums[index] = `Всего заявок ${props.summaries.count}`
      return
    }

    sums[index] = props.summaries[column.property] ? formatCurrency(props.summaries[column.property]) : ''
  })

  return sums
}

const showHistory = (id: number) => {
  emit('showHistory', id)
}

const viewOrder = (order: iOrder) => {
  emit('viewOrder', order)
}

</script>

<style lang="scss">
.order-items-service {
  display: block;
}

.table-container {
  div {
    overflow: visible;
  }

  .el-scrollbar__bar {
    display: none !important;
  }
}

.col-hidden {
  * {
    display: none;
  }
}

.el-table th.el-table__cell {
  vertical-align: top;
}

.payment-warning {
  display: block;
  color: #f00;
}
</style>
