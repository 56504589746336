<template>
  <el-menu
    mode="horizontal"
    @select="handleSelect"
  >

    <template v-for="menuItem in filteredMenu" :key="menuItem.index">
      <el-sub-menu v-if="'items' in menuItem" :index="menuItem.index">
        <template #title>{{ menuItem.title }}</template>
        <el-menu-item v-for="menuItem in menuItem.items" :key="menuItem.index" :index="menuItem.index">
          {{ menuItem.title }}
        </el-menu-item>
      </el-sub-menu>

      <el-menu-item v-else :index="menuItem.index">{{ menuItem.title }}</el-menu-item>
    </template>
  </el-menu>
</template>

<script setup lang="ts">

import { useRouter } from 'vue-router'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { isArray } from 'lodash'
import { permissions } from '@/data/permissions'

interface iMenuItem {
  index: string
  title: string
  permission?: keyof typeof permissions
  items?: iMenuItem[]
}

const store = useStore()
const router = useRouter()

const menu: iMenuItem[] = [
  {
    index: '1',
    title: 'Логист',
    items: [
      {
        index: 'orders',
        title: 'Заявки',
        permission: 'orders_page'
      }
    ]
  },
  {
    index: 'tasks',
    title: 'Задачи',
    permission: 'tasks'
  },
  {
    index: '2',
    title: 'Настройки',
    items: [
      {
        index: 'users',
        title: 'Пользователи'
      },
      {
        index: 'partners',
        title: 'Партнеры'
      },
      {
        index: 'shops',
        title: 'Магазины'
      },
      {
        index: 'locations',
        title: 'Локации'
      },
      {
        index: 'statistic',
        title: 'Статистика',
        permission: 'statistic'
      },
      {
        index: 'serviceCategories',
        title: 'Категории услуг'
      },
      {
        index: 'masters',
        title: 'Мастера',
        permission: 'masters_page'
      },
      {
        index: 'masterPlanning',
        title: 'Мастера - планирование',
        permission: 'master_planning_page'
      },
      {
        index: 'roles',
        title: 'Роли'
      },
      {
        index: 'debtors',
        title: 'Должники',
        permission: 'debtors'
      }
    ]
  }
]

const filteredMenu = computed(() => store.state.user.isAdmin ? menu : menu.map(filterMenu).filter(Boolean))

const filterMenu = (menuItem: iMenuItem) => {
  if (!isArray(menuItem.items)) {
    if (!menuItem.permission || !store.getters.can(menuItem.permission)) {
      return undefined
    }
  } else {
    menuItem.items = menuItem.items.map(filterMenu).filter(Boolean) as iMenuItem[]

    if (!menuItem.items.length) {
      return undefined
    }
  }

  return menuItem
}
const handleSelect = (name: string) => {
  router.push({ name })
}

</script>
