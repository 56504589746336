<template>
  <el-table
    v-loading="request.state.pending"
    :data="tableData"
  >
    <el-table-column prop="id" label="ID" width="80" />
    <el-table-column width="60">
      <template #default="scope">
        <el-button :icon="Edit" circle type="primary" @click="handleEdit(scope.row.id)" />
      </template>
    </el-table-column>
    <el-table-column prop="title" label="Название задачи">
      <template #default="scope">
        <el-popover
          :width="400"
          trigger="hover"
        >
          <template #reference>
            {{ scope.row.title }}
          </template>
          <template #default>
            <p>{{ scope.row.title }}</p>
            <p>{{ scope.row.description }}</p>
          </template>
        </el-popover>
      </template>
    </el-table-column>
    <el-table-column prop="status" label="Статус">
      <template #default="scope">
        <el-tag :type="scope.row.status.type">
          {{ scope.row.status.text }}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="dateStart" label="Дата постановки" />
    <el-table-column prop="dateExpected" label="Ожидаемая дата исполнения"/>
    <el-table-column prop="dateEnd" label="Фактическая дата завершения" />
    <el-table-column prop="user" label="Автор задачи" />
    <el-table-column prop="executor" label="Исполнитель" />
    <el-table-column prop="coExecutors" label="Соисполнитель(и)"/>
    <el-table-column width="60" v-if="canDelete">
      <template #default="scope">
        <el-popconfirm
          @confirm="handleDelete(scope.row.id)"
          title="Удалить эту задачу?"
        >
          <template #reference>
            <el-button :icon="Delete" circle type="danger" />
          </template>
        </el-popconfirm>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    :current-page="page"
    :page-size="pageSize"
    :total="items.length"
    hide-on-single-page
  />
</template>

<script lang="ts" setup>
import useDataRequest from '@/composables/useDataRequest'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { deleteTask, loadTasks } from '@/api/tasks'
import { iTask } from '@/types/models'
import taskStatusMap from '@/data/taskStatusMap'
import { dateDecode } from '@/utils/format'
import { Delete, Edit } from '@element-plus/icons-vue'
import { useStore } from '@/store'

const pageSize = 100

const store = useStore()

const canDelete = computed(() => store.getters.can('tasks_delete'))

const request = useDataRequest()
const route = useRoute()

const items = ref<Array<iTask>>([])
const page = ref<number>(0)

const emit = defineEmits(['edit'])

const load = () => {
  request.tryRequest(async () => {
    const response = await loadTasks({})
    items.value = response.data
  })
}

const tableData = computed(() => {
  const pageItems = items.value.slice(page.value * pageSize, page.value * pageSize + pageSize)
  return pageItems.map(item => ({
    id: item.id,
    title: item.title,
    description: item.description,
    status: taskStatusMap[item.status_id],
    dateStart: dateDecode(item.date_start),
    dateEnd: dateDecode(item.date_end),
    dateExpected: dateDecode(item.date_expected),
    user: item.user?.title || '',
    executor: item.executor?.title || 'не указан',
    coExecutors: item.coExecutors.map(i => i.title).join(', ')
  }))
})

const handleEdit = (id: number) => {
  const item = items.value.find(i => i.id === id)
  emit('edit', item)
}

const handleDelete = (id: number) => {
  request.tryRequest(async () => {
    await deleteTask(id)
    const response = await loadTasks({})
    items.value = response.data
  })
}

watch(() => route.query, load, { immediate: true })

defineExpose({
  load
})
</script>
